import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const SettingsForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedMode, setSelectedMode] = useState('Sales');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  let username = localStorage.getItem('username');
  const token = localStorage.getItem('token');

  useEffect(() => {
    // Load settings from the backend
    const loadSettings = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/admin/chatbot_settings`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            username // Send username as a query parameter
          }
        });
        const data = response.data;

        // Transform the response data to match the form's initial values
        const initialValues = {
          icon_bottom_css: `${data.chatwindow_position.bottom}`,
          icon_right_css: `${data.chatwindow_position.right}`,
          chatwindow_bottom_css: `${data.chatwindow_position.bottom}`,
          chatwindow_right_css: `${data.chatwindow_position.right}`,
          chatwindow_width: `${data.chatwindow_size.width}`,
          chatwindow_height: `${data.chatwindow_size.height}`,
          title_text: data.title,
          icon_url: data.icon_url,
          callout_text: data.callout_text,
          callout_delay: data.callout_delay,
          mode: data.mode,
        };

        form.setFieldsValue(initialValues);
      } catch (error) {
        message.error('Failed to load settings');
      }
    };

    loadSettings();
  }, [form]);

  const handleSave = async (values) => {
    setLoading(true);
    try {
      await axios.post(`${apiBaseUrl}/admin/chatbot_settings`, values, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          username: username
        }
      }, values);
      message.success('Settings saved successfully');
    } catch (error) {
      message.error('Failed to save settings');
    } finally {
      setLoading(false);
    }
  };

  const handleModeChange = (event) => { setSelectedMode(event); };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSave}
      initialValues={{
        chatwindow_bottom_css: '20px',
        chatwindow_right_css: '20px',
        chatwindow_width: '350px',
        chatwindow_height: '500px',
        title_text: 'AI Chatbot',
        icon_url: 'https://api.outscore.ai/admin/static/images/ai-chat5.png',
        callout_text: 'Click to speak!',
        callout_delay: 3000,
        mode: 'Sales',
      }}
    >
      <Form.Item label="Bot Icon Bottom CSS" name="icon_bottom_css">
        <Input />
      </Form.Item>
      <Form.Item label="Bot Icon Right CSS" name="icon_right_css">
        <Input />
      </Form.Item>
      <Form.Item label="Chat Window Bottom CSS" name="chatwindow_bottom_css">
        <Input />
      </Form.Item>
      <Form.Item label="Chat Window Right CSS" name="chatwindow_right_css">
        <Input />
      </Form.Item>
      <Form.Item label="Chat Window Width" name="chatwindow_width">
        <Input />
      </Form.Item>
      <Form.Item label="Chat Window Height" name="chatwindow_height">
        <Input />
      </Form.Item>
      <Form.Item label="Title Text" name="title_text">
        <Input />
      </Form.Item>
      <Form.Item label="Icon URL" name="icon_url">
        <Input />
      </Form.Item>
      <Form.Item label="Callout Text" name="callout_text">
        <Input />
      </Form.Item>
      <Form.Item label="Callout Delay (ms)" name="callout_delay">
        <Input type="number" />
      </Form.Item>
      <Form.Item label="Mode" name="mode" mode={selectedMode}>
        <Select placeholder="Select an option"
	        onChange={handleModeChange}
	        defaultValue="Sales" >
          <Option value="Sales">Sales</Option>
          <Option value="Info">Info</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SettingsForm;
